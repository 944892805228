import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type {
  AppInformationQuery,
  BanUserMutation,
  BanUserMutationVariables,
  ForceUserTestMutation,
  ForceUserTestMutationVariables,
  GetAbTestsConfigsQuery,
  GetUserLastSessionQuery,
  LiveOpsContentQuery,
  SearchUserQuery,
  UnbanUserMutation,
  UnbanUserMutationVariables,
  UpdateUserMutation,
  UpdateUserMutationVariables,
} from '../../../../../__gqltypes__';
import { AbTestStatus, ForceUpdateUserInput } from '../../../../../__gqltypes__';
import { GET_ABTESTS_CONFIGS } from '../../../liveops/screens/ABTests/graphql';
import {
  APP_INFORMATION,
  BAN_USER_MUTATION,
  FORCE_USER_ABTEST_MUTATION,
  GET_USER_LAST_SESSION,
  LIVE_OPS_CONTENT,
  RESET_USER_SUBSCRIPTIONS_MUTATION,
  SEARCH_USER,
  UNBAN_USER_MUTATION,
  UPDATE_USER,
} from '../../graphql';
import { formatUserItems, getItemsNotOwnedByUser } from './utils';

type SearchedUser = NonNullable<SearchUserQuery['user']>;
type LiveOps = LiveOpsContentQuery['liveOps'];
type AbTestConfig = GetAbTestsConfigsQuery['liveOps']['ABTestConfigs'][0];

const UPDATE_STATUS = {
  FAILED: 'failed',
  SUCCEED: 'succeed',
};

export const PARAM_TYPES = {
  INT: 'int',
  FLOAT: 'float',
  STRING: 'string',
  FRAGMENT: 'fragment',
  META_FRAGMENTS: 'metaFragments',
  PLAYLIST: 'playlist',
  PLAYLIST_MULTI: 'playlist-multi',
  MONTHLY_PASS: 'monthlypass',
  STICKERS: 'stickers',
  APP_SKINS: 'appSkins',
  PROFILE_FRAMES: 'profileFrames',
  ENERGY: 'energy',
  EARNED_CONTENT_PACK_IDS: 'earnedContentPackIds',
  PIGGY_BANK: 'piggyBank',
} as const;

export type ParamTypeValue = typeof PARAM_TYPES[keyof typeof PARAM_TYPES];
type EditableParamConfig = {
  key: string;
  type: ParamTypeValue;
  customPath?: string;
};

export const EDITABLE_PARAMS = [
  { key: 'userLevel', type: PARAM_TYPES.INT },
  { key: 'userProgressToNextLevel', type: PARAM_TYPES.FLOAT },
  { key: 'coinBalance', type: PARAM_TYPES.INT },
  { key: 'diamondBalance', type: PARAM_TYPES.INT },
  { key: 'premiumDays', type: PARAM_TYPES.INT },
  { key: 'vipDays', type: PARAM_TYPES.INT },
  { key: 'tournamentTicketBalance', type: PARAM_TYPES.INT },
  { key: 'boostRemove2Balance', type: PARAM_TYPES.INT },
  { key: 'boostTeamSpiritBalance', type: PARAM_TYPES.INT },
  { key: 'boostReviveBalance', type: PARAM_TYPES.INT },
  { key: 'boostNinjaBalance', type: PARAM_TYPES.INT },
  { key: 'boostBombBalance', type: PARAM_TYPES.INT },
  { key: 'boostSpeedsterBalance', type: PARAM_TYPES.INT },
  { key: 'boostArtistOnlyBalance', type: PARAM_TYPES.INT },
  { key: 'boostHurdleHintBalance', type: PARAM_TYPES.INT },
  { key: 'unlimitedBoosts.unlimitedBoostRemove2Balance', type: PARAM_TYPES.INT },
  { key: 'unlimitedBoosts.unlimitedBoostSpeedsterBalance', type: PARAM_TYPES.INT },
  { key: 'stampCards', type: PARAM_TYPES.INT },
  { key: 'totalStampCardsEarned', type: PARAM_TYPES.INT },
  { key: 'energyCoinSupply', type: PARAM_TYPES.ENERGY },
  { key: 'energyXPSupply', type: PARAM_TYPES.ENERGY },
  { key: 'energyTournamentTicketSupply', type: PARAM_TYPES.ENERGY },
  {
    key: 'metaFragments',
    type: PARAM_TYPES.META_FRAGMENTS,
    config: [
      { key: 'BRONZE', type: PARAM_TYPES.INT },
      { key: 'SILVER', type: PARAM_TYPES.INT },
      { key: 'GOLD', type: PARAM_TYPES.INT },
    ],
  },
  {
    key: 'fragmentBalance',
    type: PARAM_TYPES.FRAGMENT,
    isArray: true,
    config: [
      { key: 'id', type: PARAM_TYPES.STRING },
      { key: 'amount', type: PARAM_TYPES.INT },
    ],
  },
  {
    key: 'basicTierContentPack',
    type: PARAM_TYPES.PLAYLIST,
    config: [
      { key: 'id', type: PARAM_TYPES.STRING, customPath: 'contentPack.id' },
      { key: 'level', type: PARAM_TYPES.INT },
      { key: 'progressToNextLevel', type: PARAM_TYPES.FLOAT },
    ],
  },
  {
    key: 'stickers',
    type: PARAM_TYPES.STICKERS,
    isArray: true,
    config: [
      { key: 'id', type: PARAM_TYPES.STRING },
      { key: 'level', type: PARAM_TYPES.INT },
    ],
  },
  {
    key: 'appSkins',
    type: PARAM_TYPES.APP_SKINS,
    isArray: true,
    config: [
      { key: 'id', type: PARAM_TYPES.STRING },
      { key: 'level', type: PARAM_TYPES.INT },
    ],
  },
  {
    key: 'profileFrames',
    type: PARAM_TYPES.PROFILE_FRAMES,
    isArray: true,
    config: [
      { key: 'id', type: PARAM_TYPES.STRING },
      { key: 'level', type: PARAM_TYPES.INT },
    ],
  },
  {
    key: 'earnedContentPackIds',
    type: PARAM_TYPES.EARNED_CONTENT_PACK_IDS,
    isArray: true,
  },
  {
    key: 'currentUserMonthlyPass',
    type: PARAM_TYPES.MONTHLY_PASS,
    config: [
      { key: 'progressToNextLevel', type: PARAM_TYPES.FLOAT },
      { key: 'level', type: PARAM_TYPES.INT },
    ],
  },
  {
    key: 'piggyBank',
    type: PARAM_TYPES.PIGGY_BANK,
    config: [
      { key: 'diamonds', type: PARAM_TYPES.INT },
      { key: 'coins', type: PARAM_TYPES.INT },
    ],
  },
] as const;

type FormInput = {
  id: string;
  userLevel: number;
  userProgressToNextLevel?: number;
  coinBalance?: number;
  diamondBalance?: number;
  premiumDays?: number;
  vipDays?: number;
  tournamentTicketBalance?: number;
  boostRemove2Balance?: number;
  boostTeamSpiritBalance?: number;
  boostReviveBalance?: number;
  boostNinjaBalance?: number;
  boostBombBalance?: number;
  boostSpeedsterBalance?: number;
  boostArtistOnlyBalance?: number;
  boostHurdleHintBalance?: number;
  unlimitedBoosts?: {
    unlimitedBoostRemove2Balance?: number;
    unlimitedBoostSpeedsterBalance?: number;
  };
  stampCards?: number;
  totalStampCardsEarned: number;
  energyCoinSupply?: number;
  energyXPSupply?: number;
  energyTournamentTicketSupply?: number;
  fragmentBalance: { id: string; amount: number }[];
  basicTierContentPack?: { id: string; level: number; progressToNextLevel: number };
  stickers: { id: string; level: number }[];
  appSkins: { id: string; level: number }[];
  profileFrames: { id: string; level: number }[];
  currentUserMonthlyPass?: { progressToNextLevel: number; level: number };
  earnedContentPackIds?: string[];
  piggyBank: { coins: number; diamonds: number };
  metaFragments: { BRONZE?: number; SILVER?: number; GOLD?: number };
};
type UpdatedUser = SearchedUser;

const convertToMutationInput = (user: FormInput): ForceUpdateUserInput => {
  const cast = (value: number | string | undefined, type: ParamTypeValue) => {
    let _value: number | string | undefined = value;
    switch (type) {
      case PARAM_TYPES.INT:
      case PARAM_TYPES.ENERGY:
        _value = value ? _.toInteger(value) : 0;
        break;
      case PARAM_TYPES.FLOAT:
        _value = value ? _.toNumber(value) : 0;
        break;
      default:
        break;
    }
    return _value as number | undefined;
  };
  const castObject = (object: Record<string, string | number> | undefined, config: readonly EditableParamConfig[]) => {
    if (object === undefined) return undefined;
    const paramResult: Record<string, number | undefined> = {};
    config.forEach((item) => {
      if (item.customPath) {
        paramResult[item.key] = cast(_.get(object, item.customPath), item.type);
      } else {
        paramResult[item.key] = cast(object[item.key], item.type);
      }
    });
    return paramResult;
  };

  // After the loop it should be an Omit<ForceUpdateUserInput, 'id'>, but not yet
  const res: Partial<ForceUpdateUserInput> = {};
  EDITABLE_PARAMS.forEach((param) => {
    if ('config' in param && user[param.key]) {
      if ('isArray' in param) {
        _.set(
          res,
          param.key,

          (user[param.key] || []).map((element) => castObject(element, param.config))
        );
      } else {
        // param.key may be a nested path here (unlimitedBoosts.unlimitedBoostRemove2Balance)
        _.set(res, param.key, castObject(_.get(user, param.key), param.config));
      }
    } else {
      _.set(res, param.key, cast(_.get(user, param.key), param.type));
    }
  });
  return { id: user.id, ...(res as Omit<ForceUpdateUserInput, 'id'>), savedContentPacks: [] };
};

export const useUserView = () => {
  /** SEARCH */
  const [input, setInput] = useState('');
  const [searchUser, { data: searchData, called: searchUserCalled, loading: getUserLoading }] =
    useLazyQuery<SearchUserQuery>(SEARCH_USER);
  // @ts-ignore
  const handleInputChange = (event) => setInput(event.target.value);

  /** LOAD */
  const [getABTestsConfigs, { data: abTestConfigsData }] = useLazyQuery<GetAbTestsConfigsQuery>(GET_ABTESTS_CONFIGS);
  const [getLastSession, { data: sessionData, loading: lastSessionLoading, called: lastSessionCalled }] =
    useLazyQuery<GetUserLastSessionQuery>(GET_USER_LAST_SESSION);

  const { data: capsData, loading: appInfoLoading } = useQuery<AppInformationQuery>(APP_INFORMATION, {
    fetchPolicy: 'no-cache',
  });
  const { contentCategories } = capsData ? capsData.app : { contentCategories: [] };
  const energyCaps = useMemo(() => {
    const _energyCaps: Record<string, { coin: number; xp: number; tournamentTicket: number }> = {};
    if (capsData) {
      ['basic', 'ad_free', 'premium', 'vip', 'plus'].forEach((tier) => {
        _energyCaps[tier.toUpperCase()] = {
          // @ts-ignore
          coin: capsData.me.energySystems.coin.caps[tier]?.maxStorage ?? 0,
          // @ts-ignore
          xp: capsData.me.energySystems.xp.caps[tier]?.maxStorage ?? 0,
          // @ts-ignore
          tournamentTicket: capsData.me.energySystems.xp.caps[tier]?.maxStorage ?? 0,
        };
      });
      return _energyCaps;
    }
    return {};
  }, [capsData]);

  const { data: liveOpsData } = useQuery<LiveOpsContentQuery>(LIVE_OPS_CONTENT);
  const {
    stickers,
    appskins: appSkins,
    profileFrames,
  }: LiveOps = liveOpsData
    ? liveOpsData.liveOps
    : // @ts-ignore
      ({ stickers: [], appskins: [], profileFrames: [], __typename: 'LiveOps' } as LiveOps);

  /** UPDATE */
  // @ts-ignore
  const [formInput, setFormInput] = useState<FormInput>(null);
  const [updateStatus, setUpdateStatus] = useState<string | null>(null);
  const [user, setUser] = useState<UpdatedUser | null>(null);
  // @ts-ignore
  const [abTestConfigs, setAbTestConfigs] = useState<AbTestConfig[]>(null);
  const [updateUser, { loading: updateLoading }] = useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UPDATE_USER,
    {
      onCompleted: () => {
        setUpdateStatus(UPDATE_STATUS.SUCCEED);
      },
      onError: () => {
        setUpdateStatus(UPDATE_STATUS.FAILED);
      },
    }
  );
  const [banUser, { loading: banLoading }] = useMutation<BanUserMutation, BanUserMutationVariables>(BAN_USER_MUTATION);
  const [unbanUser, { loading: unbanLoading }] = useMutation<UnbanUserMutation, UnbanUserMutationVariables>(
    UNBAN_USER_MUTATION
  );
  const [resetUserSubscriptions, { loading: resetSubscriptionsLoading }] = useMutation(
    RESET_USER_SUBSCRIPTIONS_MUTATION,
    {
      onCompleted: (_data) => {
        handleUserUpdated(_data?.resetUserSubscriptions?.user ?? null);
      },
    }
  );

  const handleUserUpdated = useCallback((_user) => {
    let updatedUser: UpdatedUser | null = null;
    if (_user) {
      if (_user.userLevelProgressToNextLevel) {
        updatedUser = { ..._user, userProgressToNextLevel: _user.userLevelProgressToNextLevel };
      } else {
        updatedUser = _user;
      }
    }
    const _earnedContentPackIds = updatedUser?.ownedContentPacks.list.map(({ contentPack }) => contentPack.id);
    setUser(updatedUser);
    setFormInput((_formInput) => ({
      ..._formInput,
      // @ts-ignore
      premiumDays: updatedUser.premiumDays,
      // @ts-ignore
      vipDays: updatedUser.vipDays,
      // @ts-ignore
      earnedContentPackIds: _earnedContentPackIds,
    }));
  }, []);
  const [forceUserABTest] = useMutation<ForceUserTestMutation, ForceUserTestMutationVariables>(
    FORCE_USER_ABTEST_MUTATION
  );

  /** HANDLERS */
  const handleSearch = async () => {
    setUpdateStatus(null);
    let type = 'BY_ID';
    if (input.includes('@')) {
      type = 'BY_EMAIL';
    } else if (input.includes('+')) {
      type = 'BY_PHONE';
    } else if (input.length !== 36) {
      type = 'BY_NAME';
    }

    searchUser({ variables: { input, type } });
    getABTestsConfigs();
  };
  const handleSaveUser = () => {
    updateUser({ variables: { input: convertToMutationInput(formInput) } });
  };
  const handleBanUser = (userId: string) => {
    banUser({ variables: { input: { userId } } });
  };
  const handleUnbanUser = (userId: string) => {
    unbanUser({ variables: { input: { userId } } });
  };
  const handleResetUserSubscriptions = (userId: string) => {
    resetUserSubscriptions({ variables: { input: { userId } } });
  };
  const handleForceABTest = (userId: string, testId: string, variation: string) => {
    return forceUserABTest({ variables: { input: { userId, testId, variation } } });
  };
  // @ts-ignore
  const handleChangeUser = (key: string) => (event) => {
    const { value } = event.target;
    setFormInput({ ...formInput, [key]: value });
  };
  const handleChangeEarnedContentPackIds = async (earnedContentPackIds: string[]) => {
    setFormInput({ ...formInput, earnedContentPackIds });
    await updateUser({ variables: { input: convertToMutationInput({ ...formInput, earnedContentPackIds }) } });
  };
  const handleChangeBasicPlaylist = (newPlaylist: { id: string; level: number; progressToNextLevel: number }) => {
    setFormInput({ ...formInput, basicTierContentPack: newPlaylist });
  };
  const handleChangeMonthlyPass = (newUserMonthly: { progressToNextLevel: number; level: number }) => {
    setFormInput({ ...formInput, currentUserMonthlyPass: newUserMonthly });
  };
  const handleChangePiggyBank = (newPiggyBank: { coins: number; diamonds: number }) => {
    setFormInput({ ...formInput, piggyBank: newPiggyBank });
  };
  const handleChangeMetaFragments = (newMeta: { BRONZE?: number; SILVER?: number; GOLD?: number }) => {
    setFormInput({ ...formInput, metaFragments: newMeta });
  };
  const handleChangeFragment = (newFragment: { id: string; amount: number }, isNew: boolean) => {
    let newFragments = [...formInput.fragmentBalance];
    if (isNew) {
      if (!newFragments.find((f) => f.id === newFragment.id)) {
        newFragments.push(newFragment);
      }
    } else {
      // can only update fragments the user already owns
      newFragments = formInput.fragmentBalance.map((f) => (f.id !== newFragment.id ? f : newFragment));
    }
    setFormInput({ ...formInput, fragmentBalance: newFragments });
  };
  const handleAddItem = (newItem: { id: string; level: number }, type: string) => {
    if (type) {
      // @ts-ignore
      const newItems = formInput[type];
      newItems.push(newItem);
      setFormInput({ ...formInput, [type]: newItems });
    }
  };
  const handleAddAllItems = (items: { id: string; level: number }[], type: string) => {
    if (type) {
      // @ts-ignore
      const newItems = formInput[type].concat(items);
      setFormInput({ ...formInput, [type]: newItems });
    }
  };
  const handleDeleteItem = (itemToRemove: { id: string; level: number }, type: string) => {
    if (type) {
      // @ts-ignore
      const newItems = formInput[type];
      _.pullAllWith(newItems, [itemToRemove], _.isEqual);
      setFormInput({ ...formInput, [type]: newItems });
    }
  };
  const handleChangeItemLevel = (newItem: { id: string; level: number }, type: string) => {
    if (type) {
      // @ts-ignore
      const newItems = formInput[type].map((item) => (item.id === newItem.id ? newItem : item));
      setFormInput({ ...formInput, [type]: newItems });
    }
  };

  const handleSearchUrl = async (userid: string) => {
    setUpdateStatus(null);
    searchUser({ variables: { input: userid, type: 'BY_ID' } });
    getABTestsConfigs();
  };

  // @ts-ignore
  const handleKeyPressed = (target) => {
    if (target.charCode === 13) {
      target.preventDefault();
      handleSearch();
    }
  };

  const setNewUserFromBackend = (_data: SearchedUser) => {
    const {
      id,
      userLevel,
      userLevelProgressToNextLevel: userProgressToNextLevel,
      coinBalance,
      // using the new energy systems object
      energySystems: {
        tournamentTicket: { extraBalance: tournamentTicketBalance, currentCapSupply: energyTournamentTicketSupply },
        coin: { currentCapSupply: energyCoinSupply },
        xp: { currentCapSupply: energyXPSupply },
      },
      diamondBalance,
      boostRemove2Balance,
      boostTeamSpiritBalance,
      boostReviveBalance,
      boostNinjaBalance,
      boostBombBalance,
      boostSpeedsterBalance,
      boostArtistOnlyBalance,
      boostHurdleHintBalance,
      stampCards,
      totalStampCardsEarned,
      unlimitedBoosts,
      basicTierContentPack,
      stickers: userStickers,
      appSkins: userAppSkins,
      profileFrames: userProfileFrames,
      fragmentBalance,
      currentUserMonthlyPass,
      ownedContentPacks,
      piggyBank,
      metaFragments,
    } = _data;

    const _earnedContentPackIds = ownedContentPacks.list.map(({ contentPack }) => contentPack.id);
    const newFormInput: FormInput = {
      id,
      userLevel,
      userProgressToNextLevel,
      coinBalance,
      diamondBalance,
      tournamentTicketBalance: tournamentTicketBalance ?? undefined,
      boostRemove2Balance,
      boostTeamSpiritBalance,
      boostReviveBalance,
      boostNinjaBalance,
      boostBombBalance,
      boostSpeedsterBalance,
      boostArtistOnlyBalance,
      boostHurdleHintBalance,
      stampCards,
      totalStampCardsEarned,
      basicTierContentPack,
      fragmentBalance,
      stickers: formatUserItems(userStickers, stickers.list),
      appSkins: formatUserItems(userAppSkins, appSkins.list),
      profileFrames: formatUserItems(userProfileFrames, profileFrames.list),
      energyCoinSupply,
      energyXPSupply,
      energyTournamentTicketSupply,
      earnedContentPackIds: _earnedContentPackIds,
      // @ts-ignore
      piggyBank,
      metaFragments: {
        BRONZE: metaFragments.BRONZE?.amount ?? undefined,
        SILVER: metaFragments.SILVER?.amount ?? undefined,
        GOLD: metaFragments.GOLD?.amount ?? undefined,
      },
    };
    for (const [key, value] of Object.entries(unlimitedBoosts)) {
      // @ts-ignore
      newFormInput[`unlimitedBoosts.${key}`] = value;
    }
    if (userLevel >= 20 && currentUserMonthlyPass) {
      newFormInput.currentUserMonthlyPass = currentUserMonthlyPass;
    }
    setFormInput(newFormInput);
  };

  useEffect(() => {
    if (searchData && searchData.user) {
      setNewUserFromBackend(searchData.user);
      const { id } = searchData.user;
      getLastSession({ variables: { id } });
      handleUserUpdated(searchData.user);
    }
  }, [searchData, handleUserUpdated]);

  useEffect(() => {
    if (abTestConfigsData && abTestConfigsData?.liveOps) {
      const filtered = abTestConfigsData?.liveOps?.ABTestConfigs.filter(
        ({ status }) => status !== AbTestStatus.ARCHIVED
      );
      setAbTestConfigs(filtered);
    }
  }, [abTestConfigsData]);

  return {
    handleInputChange,
    handleChangeUser,
    handleSearch,
    handleSaveUser,
    handleSearchUrl,
    handleBanUser,
    handleUnbanUser,
    handleForceABTest,
    handleResetUserSubscriptions,
    updateLoading: updateLoading || banLoading || unbanLoading || resetSubscriptionsLoading,
    resetSubscriptionsLoading,
    newUser: formInput,
    contentCategories,
    stickers: formInput ? getItemsNotOwnedByUser(formInput.stickers, stickers.list) : [],
    appSkins: formInput ? getItemsNotOwnedByUser(formInput.appSkins, appSkins.list) : [],
    profileFrames: formInput ? getItemsNotOwnedByUser(formInput.profileFrames, profileFrames.list) : [],
    data: user,
    energyCaps,
    input,
    handleChangeEarnedContentPackIds,
    handleChangeBasicPlaylist,
    handleChangeMonthlyPass,
    handleChangePiggyBank,
    handleChangeFragment,
    handleAddItem,
    handleAddAllItems,
    handleDeleteItem,
    handleChangeItemLevel,
    updateStatus,
    searchUserCalled,
    getUserLoading,
    abTestConfigs,
    appInfoLoading,
    lastSession: {
      data: sessionData && sessionData.analytics ? sessionData.analytics.userLastSession : null,
      loading: lastSessionLoading,
      called: lastSessionCalled,
    },
    handleKeyPressed,
    handleChangeMetaFragments,
  };
};
